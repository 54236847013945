@font-face {
	font-family: "Jost";
	src: url("../../public/fonts/GoogleSans-Regular.eot");
	src: local("Google Sans Regular"), local("GoogleSans-Regular"),
		url("../../public/fonts/GoogleSans-Regular.eot") format("embedded-opentype"),
		url("../../public/fonts/GoogleSans-Regular.woff2") format("woff2"),
		url("../../public/fonts/GoogleSans-Regular.woff") format("woff"),
		url("../../public/fonts/GoogleSans-Regular.ttf") format("truetype");
	font-weight: normal;
	font-style: normal;
}

body,
div,
h3,
li,
ol,
p {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

h3 {
	font-weight: 400;
	font-style: normal;
	font-family: "Jost", sans-serif;
}

body {
	background-color: #fff;
}

main {
	padding: 20px 0;
	font-size: 18px;
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

body,
html {
	background-color: #fff;
	color: #1f1f1f;
	height: 100vh;
	margin: 0;
	font-family: "Roboto", sans-serif;
	font-family: "Google Sans", Roboto, Arial, sans-serif;
}

.layout {
	position: relative;
	display: flex;
	margin: 0 auto;
	width: 100%;
	max-width: max-content;
	padding-bottom: 10px;
	padding-top: 50px;
	overflow-x: hidden;
}

.container {
	width: 720px;
	margin: 0 auto;
	background-color: #fff;
	position: relative;
	width: 100%;
	max-width: 720px;
}

.header {
	display: flex;
	width: 100%;
	position: relative;
	flex-direction: column;
}

.header .head-img {
	border-radius: 22px;
	width: 72px;
	height: 72px;
	margin: 26px;
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
	margin-bottom: 0;
}

.header h1 {
	width: 100%;
	font-size: 28px;
	color: #212121;
	line-height: 35px;
	text-align: left;
	word-break: break-word;
	font-weight: 400;
	margin-top: 17px;
	margin-bottom: 8px;
	font-family: "Jost", sans-serif;
}

.header-links {
}

.header-top {
	position: relative;
	display: flex;
	width: calc(100vw - 48px);
	margin: auto;
	margin-bottom: 30px;
	margin-left: 20px;
}

.header-links span {
	display: inline-block;
	white-space: nowrap;
	font-size: 15px;
	color: #0b57cf;
	font-weight: 500;
	margin-right: 15px;
	font-family: "Jost", sans-serif;
}

.header-links span:hover {
	text-decoration: underline;
}

.header-links p {
	font-size: 12px;
	opacity: 0.6;
}

.main-btn {
	padding-top: 2px;
	height: 42px;
	background-color: #0b57cf;
	font-size: 14px;
	font-family: Roboto;
	font-weight: 400;
	color: #fff;
	border: none;
	border-bottom: 2px solid transparent;
	border-radius: 60px;
	cursor: pointer;
	outline: none;
	margin: auto;
	width: calc(100% - 40px);
}

.main-btn:focus,
.main-btn:hover {
}

.header .age {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 15px;
	height: 13px;
	background-color: #fff;
	border: 0.5px solid rgba(0, 0, 0, 0.685);
	font-family: Impact;
	color: rgba(0, 0, 0, 0.685);
	font-size: 8px;
	font-weight: 400;
	line-height: 1;
	margin-top: 9px;
}

.btn-outline {
}

.info {
	padding: 0 15px;
	margin-top: 50px;
}

.gallery {
	display: flex;
	overflow: auto;
	margin-bottom: 10px;
	width: calc(100% - 24px);
	margin-right: 0;
	margin-left: auto;
	overflow-x: auto;
}

.gallery img {
	padding: 5px 10px 5px 0;
	border-radius: 20px;
	max-height: 210px;
}

.discrip {
	position: relative;
	padding: 10px;
	width: calc(100% - 40px);
	margin: 20px auto 0;
}

.discrip-head {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	margin-bottom: 20px;
}

.discrip-head img {
	margin-right: 0;
	margin-left: auto;
	height: 14px;
}

.discrip-head h3 {
	margin-left: 0;
	margin-right: auto;
	font-family: "Jost", sans-serif;
	font-size: 21px;
}

.discrip p {
	text-align: left;
	font-size: 14px;
	line-height: 1.4;
	opacity: 0.7;
}

.discrip .text {
	position: relative;
}

.btn-outline {
	background: none;
	border: none;
	color: #3a3a3a;
	opacity: 1;
	font-size: 14px;
	font-family: "Jost", sans-serif;
	font-weight: 400;
	text-align: center;
	width: auto;
	z-index: 999;
	text-decoration: none;
	padding: 8px 15px;
	border: 1px solid #ccc;
	border-radius: 10px;
	display: inline-block;
	margin-top: 20px;
	line-height: 18px;
	letter-spacing: 0.02rem;
}

.rating {
	padding: 0 25px 25px;
	text-align: left;
}

.rating .inner {
	position: relative;
	display: flex;
	justify-content: center;
	width: 100%;
	padding: 22px 0px 0 0;
}

.rating h3 {
}

.total-rating {
	color: #333;
	font-size: 64px;
	font-weight: 100;
	line-height: 64px;
}

.user {
	display: flex;
	flex-direction: column;
	text-align: center;
	font-size: 13px;
	line-height: 27px;
	opacity: 0.4;
}

.user span {
}

.left {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/* padding-right: 20px; */
	min-width: 100px;
}

.right {
	max-width: 480px;
	width: 100%;
}

.chart {
}

.chart-bar {
}

.chart-bar > p {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 15px;
	margin-right: 5px;
	height: 20px;
	color: #737373;
	font-size: 12px;
}

.chart-bar > span {
	height: 20px;
	min-width: 10px;
	display: inline-block;
	position: relative;
}

.chart .chart-bar {
	position: relative;
	display: flex;
	height: 10px;
	margin-bottom: 9px;
	font-size: 12px;
}

.chart .chart-bar > span {
	width: 100%;
	height: 100%;
	background: rgb(232, 234, 237);
	border-radius: 10px;
	margin: 3px 0;
}

.chart .chart-bar > span::after {
	content: "";
	background: #0b57cf;
	height: 100%;
	position: absolute;
	border-radius: 100px;
}

.chart-bar.bar1 > span::after {
	content: "";
	width: 94.3%;
}

.chart-bar.bar2 > span::after {
	content: "";
	width: 15.3%;
}

.chart-bar.bar3 > span::after {
	content: "";
	width: 4%;
}

.chart-bar.bar4 > span::after {
	content: "";
	width: 5%;
}

.chart-bar.bar5 > span::after {
	content: "";
	width: 10%;
}

.comments {
	text-align: center;
	width: calc(100% - 40px);
	margin: 40px auto 0;
}

.comment {
	position: relative;
	display: flex;
	width: 100%;
	flex-direction: row;
	padding: 0;
	position: relative;
	display: flex;
	width: 100%;
	padding: 0 25px 25px;
	text-align: left;
}

.comment .avatar {
	position: relative;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	overflow: hidden;
	background-size: contain;
	flex-shrink: 0;
	margin-right: 10px;
}

.comment .avatar img {
	width: 32px;
	height: 32px;
}

.comment-info {
	position: relative;
	display: flex;
	flex-direction: column;
	width: 100%;
	padding-left: 0;
	justify-content: space-between;
}

.user-info {
	display: flex;
	align-items: center;
	margin-bottom: 22px;
}

.user-info h4 {
	color: #4c4c4c;
	font-size: 15px;
	font-weight: 400;
}

.user-info p {
	color: #aaa;
	font-size: 12px;
	font-weight: 400;
	margin-left: 0px;
}

.user-rating {
	display: flex;
}

.user-rating .stars {
}

.user-rating p {
	stop-color: rgb(95, 99, 104);
	font-family: Roboto, Arial, sans-serif;
	font-size: 12px;
	font-weight: 400;
	letter-spacing: 0.025em;
	line-height: 1rem;
	margin-left: 0;
	opacity: 0.7;
}

.comment-info .top {
	position: relative;
	display: flex;
	flex-direction: column;
}

.comment-info .stars {
	padding: 0;
	min-width: 80px;
}

.comment-info .stars img {
	width: 12px;
	height: 12px;
	margin: 0 1px;
}

.comment-text {
	position: relative;
	font-size: 14px;
	line-height: 1.4;
	text-align: left;
	margin-top: 10px;
	opacity: 0.7;
}

.responce {
	display: flex;
	flex-direction: column;
	width: 100%;
	background-color: #f2f2f2;
	border-radius: 5px;
	padding: 20px 15px;
	margin-top: 10px;
}

.responce-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 15px;
}

.responce-header > span:first-child {
	font-size: 14px;
	opacity: 0.7;
}
.responce-header > span:last-child {
	font-size: 14px;
	opacity: 0.6;
}

.responce-text {
	font-size: 14px;
	opacity: 0.7;
}

.helpful {
	font-size: 12px;
	opacity: 0.7;
	margin-top: 10px;
}

.helpful-que-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 20px;
}

.helpful-que-container span {
	font-size: 12px;
	opacity: 0.7;
}

.helpful-que-container_buttons {
	display: flex;
	align-items: center;
	gap: 10px;
}

.helpful-que-container_buttons div {
	padding: 5px 15px;
	border: 1px solid black;
	border-radius: 7px;
	font-size: 14px;
	opacity: 0.7;
}

.comment-userlikes {
	font-size: 12px;
	opacity: 0.7;
	margin-top: 20px;
}

.comment-bottom {
	font-size: 12px;
	opacity: 0.7;
	margin-top: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.comment-bottom p {
}

.like-btns {
	display: f;
}

.like-btns span {
	background: none;
	border: none;
	color: #3a3a3a;
	opacity: 1;
	font-size: 14px;
	font-family: "Jost", sans-serif;
	font-weight: 400;
	text-align: center;
	width: auto;
	z-index: 999;
	text-decoration: none;
	padding: 9px 19px;
	border: 1px solid #ccc;
	border-radius: 10px;
	display: inline-block;
	margin-left: 10px;
	line-height: 16px;
	letter-spacing: 0.02rem;
}

.mob-stats {
	display: flex;
	justify-content: flex-start;
	min-width: 100%;
	width: 100%;
	height: 60px;
	overflow-x: auto;
	margin-bottom: 10px;
}

.app-comp__mobile-info-rating svg {
	flex-shrink: 0;
	width: 16px;
	height: 16px;
	fill: #030303;
}

.mob-stat {
	align-items: center;
	width: 37%;
	position: relative;
	display: flex;
	flex-direction: column;
	min-width: 120px;
	height: 35px;
	border-right: 1px solid #ddd;
	padding-top: 1px;
	text-align: center;
	height: 40px;
}

.mob-stat:last-of-type {
	border: 0;
	width: 28%;
}

.mob-stat > span {
	display: flex;
	align-items: center;
	height: 20px;
	font-weight: 500;
	font-size: 17px;
	color: #030303;
}

.mob-stat p {
	display: flex;
	align-items: center;
	height: 20px;
	font-weight: 400;
	font-size: 12px;
	/* color: #898989; */
	opacity: 0.8;
	padding-top: 5px;
}

.age-res {
	font-size: 10px !important;
	width: 17px !important;
	height: 17px !important;
	border: 1px solid #57bb8a;
	border-radius: 100px;
	text-align: center;
	padding: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #57bb8a !important;
}

.rating {
	padding: 0 0 20px;
}

.comment {
	padding: 0 0 20px;
}

.line {
	width: 100%;
}

.total-rating {
	color: #333;
	font-size: 54px;
	font-weight: 400;
	line-height: 1.2;
	font-family: "Jost", sans-serif;
}

.mob-stats > .inner {
	display: flex;
	justify-content: flex-start;
	width: 100%;
	height: 60px;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	scrollbar-width: none;
	position: absolute;
}

.frame {
	display: flex;
	flex-direction: column;
	padding: 40px;
	border-radius: 8px;
	border: 1px solid rgb(211 211 211);
	margin-top: 30px;
	padding: 20px 20px 20px;
}

.frame-row {
	display: flex;
	margin-bottom: 13px;
}

.frame-row img {
	width: 20px;
	height: 20px;
	margin-right: 40px;
}

.frame-text {
}

.btn-link {
	display: inline-block;
	white-space: nowrap;
	font-size: 14px;
	color: #0b57cf;
	font-weight: 500;
	margin-right: 15px;
	text-decoration: none;
	margin-top: 10px;
}

.frame-text h4 {
	font-weight: 400;
	font-size: 14px;
	line-height: 1.6;
	margin-bottom: 4px;
	opacity: 0.7;
}

.frame-text p {
	font-size: 12px;
	line-height: 16px;
}

.frame-text span {
	text-decoration: underline;
}

.stars {
	display: flex;
}

.stars img {
	width: 13px;
	margin: auto 2px;
}

.chart-bar {
}

.dots {
	width: 30px;
	margin-right: 0;
	margin-left: auto;
}

@media (max-width: 840px) {
	.recommended {
		display: none;
	}

	.container {
		width: 100%;
		margin-left: 0;
		padding: 40px 0 30px;
		box-shadow: none;
	}

	.header .head-img {
		overflow: hidden;
		border-radius: 20%;
		width: 72px;
		height: 72px;
		margin-left: 0;
		margin-top: 0;
		margin-bottom: 0;
	}

	.info {
		padding: 0;
		margin: auto;
	}

	.header h1 {
		max-width: 240px;
		font-size: 25px;
		line-height: 32px;
		padding: 0;
		margin: 0;
		font-weight: 400;
	}

	.layout {
		padding-top: 0;
	}

	.main-btn {
		position: relative;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		display: block;
	}

	.header {
		display: flex;
		flex-direction: column;
		margin-bottom: 20px;
	}

	.discrip {
		padding: 0;
	}

	.header-top {
	}

	.bottom {
		padding: 0 0 20px;
	}

	.footer {
		padding: 0;
	}

	.comments {
	}
}

.btns {
	display: flex;
	padding: 0 24px;
	justify-content: space-between;
}

.btn-secondary {
	height: 42px;
	background-color: #ffffff;
	font-size: 14px;
	font-family: Roboto;
	font-weight: 400;
	color: #5985ca;
	border: none;
	border: 1px solid #cdcdcd;
	border-radius: 60px;
	cursor: pointer;
	outline: none;
	margin: auto;
	width: 100%;
}

.btns .btn-secondary {
	width: 48%;
	margin: 0 auto 0 0;
}

.btn-secondary.cancel {
	/* color: #acacac; */
}

.btns .main-btn {
	width: 48%;
	margin: 0 0 0 auto;
}

.main-btn.disabled {
	background: #e6e6e6;
	color: #b7b7b7;
}

.header .img-download {
	width: 72px;
	height: 72px;
	display: flex;
	margin-right: 20px;
	margin-bottom: -0;
}

.header .img-download img {
	width: 32px;
	height: 32px;
	margin: auto;
	padding: 0;
}

.img-loading-circle-svg {
	position: absolute;
	width: 72px;
	height: 72px;
	transform: rotate(-90deg);
	stroke-dasharray: 220;
	stroke-dashoffset: 220;
	transition: stroke-dashoffset 0.2s linear;
}

.header .img-loading {
	width: 72px;
	height: 72px;
	display: flex;
	margin-right: 20px;
	margin-bottom: -0;
}

.header .img-loading img {
	width: 32px;
	height: 32px;
	margin: auto;
	padding: 0;
}

.img-loading-circle {
	position: absolute;
	width: 72px;
	height: 72px;
}

.img-loading::after {
	content: "";
	position: absolute;
	width: 72px;
	height: 72px;
	border-radius: 100px;
	border-left: 0;
	border-bottom: 0;
	animation: spinner-border 1.75s linear infinite;
	opacity: 0.75;
	background-image: url(../../public/images/other/loader.png);
	background-size: 100%;
	background-repeat: no-repeat;
}

@keyframes spinner-border {
	to {
		transform: rotate(360deg);
	}
}

@keyframes spinner-grow {
	0% {
		transform: scale(0);
	}

	50% {
		opacity: 1;
	}
}

.header-loding {
}

.header-loding h3 {
	font-size: 17px;
	line-height: 1;
	font-weight: 500;
}

.header-loding p {
	line-height: 1;
}

.header-loding p img {
	width: 10px;
	margin-right: 5px;
}

.header-loding p span {
	font-size: 12px;
	opacity: 0.7;
}

.Gallery_gallery__W86p0 {
	position: relative;
	/* padding: 24px; */
	width: calc(100% - 40px);
	margin: 20px auto 0px;
}

.Gallery_buttonPrev__GjkHI,
.Gallery_buttonNext__\+NwwV {
	position: absolute;
	min-width: 40px;
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: 56px;
	height: 56px;
	padding: 0;
	border: none;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
	color: rgb(95, 99, 104);
	font-size: 24px;
	fill: currentColor;
	text-decoration: none;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	overflow: visible;
	transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms,
		transform 0.27s 0ms cubic-bezier(0, 0, 0.2, 1);
}
.Gallery_buttonPrev__GjkHI:hover,
.Gallery_buttonNext__\+NwwV:hover {
	background-color: rgb(242, 242, 242);
}
@media (max-width: 600px) {
	.Gallery_buttonPrev__GjkHI,
	.Gallery_buttonNext__\+NwwV {
		display: none;
	}
}
.Gallery_buttonPrev__GjkHI > span,
.Gallery_buttonNext__\+NwwV > span {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	font-weight: 200;
	width: 36px;
	height: 36px;
}

.Gallery_buttonPrev__GjkHI {
	left: 0px;
	top: 50%;
	transform: translateX(-50%) translateY(-50%);
}

.Gallery_buttonNext__\+NwwV {
	right: 0px;
	top: 50%;
	transform: translateX(50%) translateY(-50%);
}

.Gallery_galleryContainer__-p-ic {
	width: 100%;
	display: flex;
	flex-direction: row;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	scrollbar-width: none;
}
@media screen and (min-width: 600px) {
	.Gallery_galleryContainer__-p-ic {
		margin-bottom: 16px;
	}
}
@media screen and (min-width: 1280px) {
	.Gallery_galleryContainer__-p-ic {
		margin-bottom: 24px;
	}
}
.Gallery_galleryContainer__image__QTM3U {
	display: block;
	border-width: 0;
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
	border-radius: 8px;
	cursor: pointer;
	height: 204px;
	margin-right: 12px;
	scroll-snap-align: start;
}
.Gallery_galleryContainer__image__QTM3U:last-child {
	margin-right: 0px;
}
@media screen and (min-width: 1280px) {
	.Gallery_galleryContainer__image__QTM3U {
		margin-right: 16px;
		height: 296px;
	}
}

.Layout_imagePopup__CCOgX {
	display: none;
	transition: opacity 0.15s cubic-bezier(0.4, 0, 0.2, 1) 0.15s;
	bottom: 0;
	left: 0;
	opacity: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 5000;
	transition: opacity 0.05s cubic-bezier(0.4, 0, 0.2, 1);
	opacity: 1;
	background-color: rgba(0, 0, 0, 0.7);
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
}

.openImage {
	display: flex;
}

.Layout_imagePopup__header__n7kd7 {
	align-items: center;
	display: flex;
	height: 64px;
	background-color: #000;
	width: 100%;
}

.Layout_imagePopup__header__n7kd7 button {
	background-color: transparent;
	border: none;
	outline: none;
	width: 48px;
	height: 48px;
	margin-left: 16px;
	color: #e8eaed;
	cursor: pointer;
}

.Layout_imagePopup__body__nwj57 {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Gallery_buttonNext__\+NwwV {
	right: 0px;
	top: 50%;
	transform: translateX(50%) translateY(-50%);
}

.Gallery_buttonPrev__GjkHI,
.Gallery_buttonNext__\+NwwV {
	position: absolute;
	min-width: 40px;
	min-height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	width: 56px;
	height: 56px;
	padding: 0;
	border: none;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
	color: rgb(95, 99, 104);
	font-size: 24px;
	fill: currentColor;
	text-decoration: none;
	cursor: pointer;
	-webkit-user-select: none;
	user-select: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	overflow: visible;
	transition: box-shadow 0.28s cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms,
		transform 0.27s 0ms cubic-bezier(0, 0, 0.2, 1);
}

@media (max-width: 600px) {
	.Gallery_buttonPrev__GjkHI,
	.Gallery_buttonNext__\+NwwV {
		display: none;
	}
}

.Layout_buttonNext__p4gU9 {
	position: absolute !important;
	right: 32px !important;
	width: 48px !important;
	height: 48px !important;
	transform: translateX(0%) translateY(-50%) !important;
	display: flex;
	z-index: 20 !important;
}

.Layout_buttonPrev__IU3Mn {
	position: absolute !important;
	left: 32px !important;
	width: 48px !important;
	height: 48px !important;
	transform: translateX(0%) translateY(-50%) !important;
	display: flex;
	z-index: 20 !important;
}

.Gallery_buttonNext__\+NwwV > span {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 30px;
	font-weight: 200;
	width: 36px;
	height: 36px;
}

.Layout_imagePopup___nwj57 {
	width: 100%;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.Layout_imagePopup__image {
	max-height: 90%;
	max-width: 80%;
	object-fit: contain;
	z-index: 10;
	background-size: contain;
	width: 100%;
}

.line-sections {
	width: 100%;
	height: 1px;
	background-color: #c7c7c7;
}

@media (min-width: 840px) {
	.line-sections {
		display: none;
	}
}

.current-platform {
	width: calc(100% - 40px);
	margin: 20px auto 0;
	display: flex;
	justify-content: flex-start;
}

.current-platform_platform {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 5px;
	background-color: #c4e2f6;
	padding: 5px 15px;
	font-size: 14px;
	border-radius: 7px;
}

.all-reviews {
	width: calc(100% - 40px);
	margin: 10px auto 0;
}

.support {
	container-type: inline-size;
	container-name: section-wrapper;
	padding-top: 20px;
	width: 100%;
	width: calc(100% - 40px);
	margin: 10px auto 40px;
}
@media screen and (min-width: 600px) {
	.support {
		margin-bottom: 16px;
	}
}
@media screen and (min-width: 1280px) {
	.support {
		margin-bottom: 24px;
	}
}

.support .blockHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.support .blockHeader div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.support .blockHeader h3 {
	margin-left: 0;
	margin-right: auto;
	font-family: "Jost", sans-serif;
	font-size: 21px;
}

#openSupport {
	position: absolute;
	opacity: 0;
	visibility: hidden;
}

.support__list {
	display: none;
	gap: 24px;
	color: rgb(95, 99, 104);
	fill: rgb(95, 99, 104);
	stop-color: rgb(95, 99, 104);
	font-size: 0.875rem;
	font-weight: 400;
	letter-spacing: 0.0142857143em;
	line-height: 1.25rem;
	font-family: Roboto, Helvetica, Arial, sans-serif;
	margin-top: 20px;
}

#openSupport:checked ~ .support__list {
	display: grid;
}

#openSupport:checked ~ .blockHeader #labelSupport img:first-child {
	display: none;
}

.blockHeader #labelSupport img:last-child {
	display: none;
}

#openSupport:checked ~ .blockHeader #labelSupport img:last-child {
	display: block;
}

.support__item {
	border-radius: 8px;
	display: flex;
	padding: 12px;
	margin: -12px;
	cursor: pointer;
}
.support__item:hover {
	background-color: rgb(242, 242, 242);
}
.support__item span {
	color: rgb(95, 99, 104);
	font-size: 18px;
	margin-right: 16px;
}
.support__item__titleWrapper {
	display: flex;
	flex-direction: column;
}
.support__item__title {
	font-family: "GSMedium", sans-serif;
	font-size: 14px;
	color: rgb(32, 33, 36);
}
.support__item__subTitle {
	color: rgb(95, 99, 104);
	font-family: Roboto, Helvetica, Arial, sans-serif;
	font-size: 14px;
}
.games {
	container-type: inline-size;
	container-name: section-wrapper;
	padding-top: 20px;
	width: 100%;
}
@media screen and (min-width: 600px) {
	.games {
		margin-bottom: 16px;
	}
}
@media screen and (min-width: 1280px) {
	.games {
		margin-bottom: 24px;
	}
}
.games__list {
	display: flex;
	grid-gap: 10px;
	overflow-x: scroll;
	scroll-snap-type: x mandatory;
	scrollbar-width: none;
}

.games__itemWrapper {
	scroll-snap-align: start;
}
.games__item {
}
@media screen and (min-width: 1280px) {
	.games__item {
		margin: 16px 12px;
	}
}
.games__item img {
	border-radius: 12px;
	border-width: 0;
	box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
	height: 98px;
	width: 98px;
	background-size: contain;
}

.games__item__info {
	display: grid;
	grid-template-rows: max-content;
	height: -moz-fit-content;
	height: fit-content;
	row-gap: 2px;
}
@media screen and (min-width: 1280px) {
	.games__item__info {
		row-gap: 0;
	}
}
.games__item__textContainer {
	align-items: center;
	display: inline-flex;
	font-size: 14px;
	color: rgb(32, 33, 36);
}
.games__item__subTitle {
	font-size: 14px;
}
.games__item__raiting {
	display: flex;
	align-items: center;
	color: rgb(95, 99, 104);
	font-size: 14px;
}

.similarGames {
	width: calc(100% - 40px);
	margin: 10px auto 0;
}

.similarGames-container {
	width: 100%;
}

/* comments popup */

.commentsPopup {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	min-height: 100vh;
	background-color: #fff;
}

.loaderContainer {
	position: relative;
	width: 100vw;
	height: 100dvh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader {
	width: 48px;
	height: 48px;
	border: 5px solid #0b57cf;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/* pwa */

.loaderContainer {
	position: relative;
	width: 100vw;
	height: 100dvh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader {
	width: 48px;
	height: 48px;
	border: 5px solid #0b57cf;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.Play_banner__3uFUZ {
	position: absolute;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100vh;
	background-color: black;
	background-image: url(../../public/images/pwa/1920x1080.png);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	padding: 50px 15px;
}
@media (max-width: 1024px) {
	.Play_banner__3uFUZ {
		background-image: url(../../public/images/pwa/1024x1366.png);
		padding: 90px 15px 50px 15px;
	}
}
@media (max-width: 830px) {
	.Play_banner__3uFUZ {
		padding: 150px 15px 50px 15px;
	}
}
@media (max-width: 600px) {
	.Play_banner__3uFUZ {
		padding: 100px 15px 110px 15px;
		background-image: url(../../public/images/pwa/370x812.png);
	}
}

